a, button {
    transition: all 0.1s ease-in-out 0s;
}
#recomended ul, #recomended li {
    margin-left: 30px;
}

.bg1 {
    background-color: #e8ecef;
    
    .bg1 .divider.divider-border , .divider.divider-border {
        color: #d0d4d8;
    }
    .divider:after, .divider.divider-center:before, .divider.divider-center.divider-short:before {
         border-top: 1px solid #d0d4d8;
    }
    
}
.bg2 {
    background-color: #231f20;
    
    h1, h2, h3, h4, h5, p, a {
        color: #fff;
    }
}
.line {
    border-bottom: 1px solid @border-color;
    margin: 30px 0;
}
.list-group-item.active, .list-group-item.active:hover, .list-group-item.active:focus {
    background-color: #032a6f;
}

.box-white {
    border: 1px solid #dfdfdf;
    background: #fff;
    border-radius: 4px;
    padding: 25px 35px;
    font-size: 16px;
    color: #707070;
}

#kontakt-form input, #kontakt-form textarea {
    margin: 0;
    border-width: 1px;
    border-radius: 4px!important;
}
#kontakt-form input {
    margin-bottom: 8px;
}
#kontakt-form input:focus, #kontakt-form textarea:focus {
    border-color: #d26f18;
}
#kontakt-form textarea {
    height: 177px;
}
.btn-kontakt {
    width: 100%;
}
.btn-primary {
    color: #ffffff;
    background-color: @theme-color;
    border-color: @theme-color;
}
#copyrights {
    font-size: 16px;
}
#copyrights a {
    color: #fff;
}
#copyrights a:hover {
   // color: #e76e45;
    color: #d92417;
}
#piksel img{
    opacity: 0.5;
    transition: all 0.1s ease-in-out 0s;
    -webkit-filter: grayscale(100%);
    -moz-filter: grayscale(100%);
    -o-filter: grayscale(100%);
    -ms-filter: grayscale(100%);
    filter: grayscale(100%);
}
#piksel img:hover{
    opacity: 1;
    -webkit-filter: grayscale(0%);
    -moz-filter: grayscale(0%);
    -o-filter: grayscale(0%);
    -ms-filter: grayscale(0%);
    filter: grayscale(0%);
}
.btn-primary:hover {
    color: #ffffff;
    background-color: #032a6f;
    border-color: #032a6f;
}
.button-red {
    background-color: #d92417;
}

.button-reveal.button-red:hover {
        background-color: #032a6f !important
}
.justify {
    text-align: justify;
}


#logo-bg {
    background-color: #e42233;
}

.dark #primary-menu:not(.not-dark) ul li .mega-menu-content.style-2, #primary-menu.dark ul li .mega-menu-content.style-2 {
    border-top-color: @theme-color!important;
}

#logo2 {
    margin-top:20px;
} 


.menu-box {
    
    img { 
        float: left;
        width: 30%;
        max-width: 70px;
        margin: 0 15px 15px 0;
    }
    div.right-menu {
        float: left;
        width: 60%;
        
        > h3 {
            font-size: 16px;
            line-height: 20px;
            margin: 0 0 10px 0;
        }
        > p {
            text-transform: none;
            color: #9a9a9a;
        }
    }
}

.cat-home {
    a {
        display: block;
        border: 1px solid #d5d5d5;
        clear: both;
        margin-bottom: 15px;
       padding: 10px 0;
       min-height: 90px;
        
        img {
            float: left;
            width: 30%;
            max-width: 70px;
            margin: 0 15px 15px 0;
        }
        h3 {
            font-size: 18px;
            line-height: 20px;
            margin: 0 0 5px 0;
            font-size: 16px;
            color: @theme-color;
            text-transform: uppercase;
        }
        p {
            text-transform: none;
            color: #9a9a9a;
            font-size: 13px;
            line-height: 13px!important;
            margin-bottom: 0px;
        }
    }
}

.recommended-product {
    text-align: center;
    
    img {
        border: 1px solid #d5d5d5;    
    }
    p {
        text-align: center;
        
        a {
            color: @body-color;
        }
        span {
            color: @theme-color;
            font-weight: bold;
        }
    }
}

#footer.dark, .dark #footer {
    background-color: @theme-color!important;
    color: #fff!important;
}

#copyrights {
    a {
        color: #ffdcdf;
        text-transform: uppercase;
        font-size: 13px;
        margin: 0 10px;
    }
}
#copyrights2 {
    background-color: #fff;
    color: #8a8a8a;
    font-size: 13px;
    
    a {
        color: #8a8a8a;
    }
}
.footer-top {
    padding: 30px 0;
}
#content .content-wrap {
    padding:0;
}
#news {
    .button.button-border {
        color: #fff;
        border-color: #fff;
    }
}

#page-title {
    padding: 10px 0;
    font-size: 12px;
    
    a {
        color: @body-color;
        padding: 0 20px;
    }
}

h3 .small {
    color: @body-color!important;
    }
    
.product {
    .flex-viewport {
        border: 1px solid @border-color;
    }
    
    .flex-control-nav.flex-control-thumbs li, .flex-control-nav.flex-control-thumbs li img {
        width: auto !important;
        height: 75px !important;
    }
   .pdf {
        margin: 15px 20px 15px 0; 
        text-align: center;
        font-weight: bold;
        
        i { font-size: 18px;
            margin-right: 5px;
        }
        a {
            color: @body-color;
        }
    }
    .product-contact-info {
        border-top: 1px solid @border-color;
        border-bottom: 1px solid @border-color;
        text-align: center;
        padding: 20px;
        margin: 10px 0;
    }
}    

.product-promo-info {
    background-color: @body-color;
    color: #fff;
    font-weight: bold;
    font-size: 14px;
    line-height: 1.3;
    text-align: center;
    padding: 6px 15px;
    margin-bottom: 10px;
}
    
.product-desc {
    padding-left: 30px;
    
    h1 {
        font-size: 28px;
        border-left: 3px solid @theme-color;
        padding-left: 25px;
        color: @body-color;
        font-weight: normal;
        margin-bottom: 10px;
    }
    .line {
        margin-bottom: 40px;
    }
  
}


.product-info {
    border: 1px solid @border-color;
    text-align: center;
    padding: 30px 40px;
    margin-right: 20px;
    
    .price-cat {
        border-bottom: 1px solid @border-color;
        margin-bottom: 10px;
        padding-bottom: 20px;
    }
    .price-main {
        color: @theme-color;
        font-size: 30px;
        margin:0;
        line-height: 1.2!important;
        
        span {
            font-size: 15px;
        }
        .promo-span {
            font-weight: bold;
            font-size: 18px;
        }
        
    }
    .price-netto {
        border-bottom: 1px solid @border-color;
        margin-bottom:25px;
        padding-bottom: 15px;
    }
}

.recommended-product {
    text-align: center;
    img {
        max-height: 140px;
        width: auto;
        border: 0;
    }
    .img-box {
        border: 1px solid @border-color;
        height: 150px;
        margin-bottom: 10px;
        background-color: #fff;
    }
}

.attoff {
    background-color:#e42233;
    text-transform:uppercase; 
    font-size:18px;
    color:#fff!important;
    padding:3px 10px;
 //   margin-left:10px;
}


#cat-list {
    padding-top:20px;
    
    h2 {
        font-size: 28px;
       /* border-left: 3px solid @theme-color;
        padding-left: 25px;*/
        
        font-weight: normal;
        margin-bottom: 10px;
        
        a {
            //margin-top:5px;
            line-height: 1.5;
            color: @body-color;
            
            .trans
        }
        a:hover {
            color: @theme-color;
        }
    }

}

.alert {
	ul {
		padding-left: 25px;
	}
}

.cat-list-square {
	
	.prod-img {
		text-align: center;
		display: table;
		margin: 0 auto;
		
		a {
			display: table-cell;
			vertical-align: middle;
			text-align: center;
			
		}
	}
	h3 {
			font-weight: 400;
			margin-bottom: 3px;
			text-align: center;
			
			.prod-list-price {
				color: @theme-color;
			}
	}
	h2 {
		font-size: 22px !important;
		line-height: 24px;
		margin-bottom: 30px!important;
		text-align: center;
		
		.attoff {
			font-size: 12px;
			margin-right:0!important;
		}
	}
	
	
}


.cat-list-line {
	h2 {
		line-height:1.1;
		margin-bottom:0!important;
		.attoff {
			font-size: 12px;
			margin-right:0!important;
		}
	}
	h3 {
			font-weight: 400;
			margin-bottom: 3px;
			
			.prod-list-price {
				color: @theme-color;
			}
	}
	.prod-img {
			
	}

}



#cat-list {
	img {
		transition: all 0.1s ease-in-out 0s;
	}
	img:hover {
		opacity: 0.7;
	}
	.prod-item {
		margin-bottom:25px;
	}
}



.category-box {
	float:left;
	margin:0 20px 20px 0;
	width: 150px;
	height: 150px;
	padding:5px;
	text-align: center;
}

.change-view {
	float:right; 
	
	small {
		font-size: 13px;
		margin-top: -10px;
	}
	i {
		font-size: 20px;
	}
}

.alert-info {
    background-color: #F5F5F5;
    border-color: #e6e6e6;
    
}
#oblicz-rate {
	margin-top: 5px;
}


.tab-name-checkout {
	font-weight: bold;
	text-align: right;
	padding-right: 30px;
}
.btn-success {
	background-color: @theme-color;
	border-color: @theme-color;
	 transition: all 0.1s ease-in-out 0s;
}
.btn-success:hover {
	background-color: @body-color;
	border-color: @body-color;
}
.btn-success[disabled]:hover, {
	background-color: @body-color;
		border-color: @body-color;
}

@media (max-width: 991px) {
    #kontakt-prawa {
        padding-left: 0;
    }
    #kontakt-lewa {
        padding-right: 0;
        margin-bottom: 15px;
    }
    
}

