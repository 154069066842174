// ******       MIN AND MAX       ************ //

@media (max-width:479px) {
	
	#logo2 {
	    margin-top: 0px;
	    margin-bottom: 20px;
	}
	
	.cat-list-square {
		.prod-img {
			height: 220px;
			text-align: center;
			
			img {
				max-width: 250px!important;
				max-height: 180px;
			}
		}
	}
	.cat-list-line {
		.prod-img {
			text-align: center;
			img {
				max-height: 200px;
			}
		}
	}
}


@media (min-width:480px) and (max-width:767px) {
	
	#logo2 {
	    margin-top: 0px;
	    margin-bottom: 20px;
	}
	
	.cat-list-square {
		.prod-img {
			height: 280px;
			text-align: center;
			
			img {
				max-width: 300px!important;
				max-height: 230px;
			}
		}
	}
	
	.cat-list-line {
		.prod-img {
			text-align: center;
			img {
				max-height: 200px;
			}
		}
	}
	
	
}

@media (min-width:768px) and (max-width:991px) {
	

	#logo2 {
	    margin-top: 0px;
	    margin-bottom: 20px;
	}




	.cat-list-square {
		.prod-img {
			height: 220px;
			text-align: center;
			
			img {
				max-width: 250px!important;
				max-height: 180px;
			}
		}
	}
	
	.cat-list-line {
		.prod-img {
			text-align: center;
			img {
				max-height: 130px;
			}
		}
	}
	
	#primary-menu ul li.sub-menu {
		
		img {
			display:none;
		}	
	}
	#primary-menu .mega-menu-column .widget {
		padding:5px 0;	}
	
	.menu-box div.right-menu > h3 {
		margin: 0;
		font-size: 14px;
		line-height: 20px;
	}
	
}

@media (min-width:992px) and (max-width:1199px) {
	.cat-list-square {
		.prod-img {
			height: 250px;
			text-align: center;
			
			img {
				max-width: 300px!important;
				max-height: 200px;
			}
		}
	}
	.cat-list-line {
		.prod-img {
			text-align: center;
			img {
				max-height: 130px;
			}
		}
	}
}

@media (min-width:1200px) {
	.cat-list-square {
		.prod-img {
			height: 320px;
			text-align: center;
			
			img {
				max-width: 320px!important;
				max-height: 250px;
			}
		}
	}
	
	.cat-list-line {
		.prod-img {
			text-align: center;
			img {
				max-height: 130px;
			}
		}
	}
	
	
	
}